// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, browserLocalPersistence, browserSessionPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import * as firebaseui from "firebaseui";
//import { getMessaging } from "firebase/messaging/sw";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyAJ38CRNE0rtTEWqhAov4cSO6TKinGYODQ",
    authDomain: "trefimto.firebaseapp.com",
    projectId: "trefimto",
    storageBucket: "trefimto.appspot.com",
    messagingSenderId: "570475241659",
    appId: "1:570475241659:web:e837c1f349da72e7be899e",
    measurementId: "G-FJCY63Q89R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
let auth = getAuth(app);
auth.languageCode = 'cz';
auth.setPersistence(browserSessionPersistence).then(r =>console.log(r));


let authUiInstance: firebaseui.auth.AuthUI;

export const getAuthUiInstance = (): firebaseui.auth.AuthUI => {
    if (!authUiInstance) {
        authUiInstance = new firebaseui.auth.AuthUI(auth);
    }
    return authUiInstance;
};

const analytics = getAnalytics(app);
//const messaging = getMessaging(app);

export { app, auth, analytics };
