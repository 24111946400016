import { useState } from "react";
import { Button, Layout, Modal } from "antd";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";

const { Header } = Layout;

const AppHeader = () => {
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [registerModalVisible, setRegisterModalVisible] = useState(false);

    const showLoginModal = () => {
        setLoginModalVisible(true);
    };

    const handleLoginModalCancel = () => {
        setLoginModalVisible(false);
    };

    const showRegisterModal = () => {
        setRegisterModalVisible(true);
    };

    const handleRegisterModalCancel = () => {
        setRegisterModalVisible(false);
    };

    return (
        <Header>
            <div style={{ float: "right" }}>
                <Button onClick={showLoginModal}>Login</Button>
                <Button type="primary" onClick={showRegisterModal}>
                    Register Now
                </Button>
            </div>
            <LoginModal
                visible={loginModalVisible}
                onCancel={handleLoginModalCancel}
                onRegister={showRegisterModal}
            />
            <RegisterModal
                visible={registerModalVisible}
                onCancel={handleRegisterModalCancel}
                onRegister={() => { console.log("register") }}
            />
        </Header>
    );
};

export default AppHeader;
