import { createContext, useEffect, useState } from 'react';
import { User,
    getAuth,
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    UserInfo,
    sendEmailVerification,
    signInWithPhoneNumber,
    ConfirmationResult
} from 'firebase/auth';
import {auth} from "../FirebaseInit";


type AuthContextType = {
    isLoggedIn: boolean;
    userInfo: UserInfo | null;
    user: User | null;
    signUp: (email: string, password: string) => User|null;
    login: (email: string, password: string) => User|null;

    sendEmailVerificationToCurrentUser: (currentUser: User) => void;
    logout: () => void;
};

export const AuthContext = createContext<AuthContextType>({
    isLoggedIn: false,
    userInfo: null,
    user: null,
    signUp: (): User => {
        return {} as User;
    },
    login: (): User => {
        return {} as User;
    },
    sendEmailVerificationToCurrentUser: (currentUser: User): void => {},
    logout: () => {},
});

type Props = {
    children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserInfo(user);
                setUser(user);
                setIsLoggedIn(true);
            } else {
                setUserInfo(null);
                setUser(null);
                setIsLoggedIn(false);
            }
        });
    }, []);

    const sendEmailVerificationToCurrentUser = (currentUser: User) => {
        if (currentUser) {
            sendEmailVerification(currentUser)
                .then(() => {
                    console.log('Email verification sent');
                })
                .catch((error) => {
                    console.error(error);
                    throw error;
                });
        } else {
            throw new Error('No user logged in');
        }
    }

    //login through mobile phone number
    // const handleLoginWithPhoneNumber = async (phoneNumber: string) => {
    //     const applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    //     const verificationCode = await signInWithPhoneNumber(auth, phoneNumber, applicationVerifier)
    //         .catch((error) => {
    //             // Error; SMS not sent
    //             // ...
    //         });
    //     const confirmationResult = new ConfirmationResult().confirm(verificationCode)
    //     // Obtain a verificationCode from the user.
    //     const credential = await confirmationResult.confirm(verificationCode);
    // }

    const login = (): User|null => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;
                console.log(`Logged in as ${user.email}`);
                return user;
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
        return null;
    };

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                console.log('Logged out');
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    };

    const handleSignUp = (email: string, password: string): User|null => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log(`Signed up as ${user.email}`);
                return user;
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
        return null;
    };

    const handleLoginWithEmail = (email: string, password: string): User|null => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log(`Logged in as ${user.email}`);
                return user;
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
        return null;
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, userInfo: userInfo, user: user, login: handleLoginWithEmail, sendEmailVerificationToCurrentUser: sendEmailVerificationToCurrentUser, logout: handleLogout, signUp: handleSignUp }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
