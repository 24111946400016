import React, { createContext, useState } from 'react';

interface PriceContextType {
    price: number;
    setPrice: (price: number) => void;
}

export const PriceContext = createContext<PriceContextType>(
    {
        price: 0,
        setPrice: (price: number) => {},
    }

);

type Props = {
    children: React.ReactNode;
};
export const PriceProvider = ({ children }: Props) => {
    const [price, setPrice] = useState(0);

    console.log("PriceProvider price: ", price);
    return (
        <PriceContext.Provider value={{price: price, setPrice: setPrice }}>
            {children}
        </PriceContext.Provider>
    );
};
