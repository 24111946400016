import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';

type RegisterModalProps = {
    visible: boolean;
    onCancel: () => void;
    onRegister: (email: string, password: string) => void;
};

const RegisterModal: React.FC<RegisterModalProps> = ({
                                                         visible,
                                                         onCancel,
                                                         onRegister,
                                                     }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleRegister = async () => {
        try {
            setLoading(true);
            const { email, password } = await form.validateFields();
            onRegister(email, password);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal visible={visible} title="Register" onCancel={onCancel} footer={null}>
            <Form form={form} layout="vertical">
                <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
                    <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        { required: true },
                        { min: 6, message: 'Password must be at least 6 characters long' },
                    ]}
                >
                    <Input.Password placeholder="Enter your password" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={handleRegister} loading={loading} block>
                        Register
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default RegisterModal;
