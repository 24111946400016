import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import "firebase/auth";

const GetUserToken = ():string|null => {
    const { user } = useContext(AuthContext);
    const [token, setToken] = useState<string | null>(null);

    user?.getIdToken().then((token) => {
        setToken(token);
    });

    return token;
}

export const SignInSignUp: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [isSignIn, setIsSignIn] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const { userInfo, login, signUp, user, logout, sendEmailVerificationToCurrentUser } = useContext(AuthContext);

    const firebaseUserToken = GetUserToken();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage("");
        try {
            if (isSignIn) {
                login(email, password);
            } else {
                signUp(
                    email,
                    password
                );
            }
        } catch (error) {
            // @ts-ignore
            setErrorMessage(error.message);
        }
    };

    return userInfo ? (
        // redirect to home page
        <div>Logged in as...
            <p>{userInfo.email}</p>
            {userInfo.email && <p>{userInfo.uid}</p>}
            {user && <p>Is user email-verified: {user?.emailVerified ? "yes" : "no"}</p>}
            {firebaseUserToken && <p>firebaseUserToken: {firebaseUserToken}</p>}
            <button onClick={() => logout()}>Logout</button>
            <button onClick={() => sendEmailVerificationToCurrentUser(user!)}>Send email verification</button>
        </div>


    ) : (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {!isSignIn && (
                    <div className="form-group">
                        <label htmlFor="displayName">Display Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="displayName"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                    </div>
                )}
                <button type="submit" className="btn btn-primary">
                    {isSignIn ? "Sign In" : "Sign Up"}
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsSignIn(!isSignIn)}
                >
                    {isSignIn ? "Need to create an account?" : "Already have an account?"}
                </button>
            </form>
            {errorMessage
                ? <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
                : null}
        </div>
    );
};



