import React, {useContext, useEffect, useState} from 'react';
import ImageGallery, {ReactImageGalleryItem} from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {notify} from "./Notifications/NotificationListener";
import {NotifyEnum} from "./Notifications/NotifyEnum";
import {ToastOptions} from "react-toastify";
import PriceReminderForm from "./PriceReminder/PriceReminder";
import {PriceContext} from "../contexts/PriceContext";
import {WebSocketAuctionData} from "../Infrastructure/WebSocketAuctionData";
import axios from "axios";
import {IAuctionData} from "../Infrastructure/Data/IAuctionData";

interface AuctionProps {
    name: string;
    image: string;
    description: string;
    startingPrice: number;
    reservePrice?: number;
    duration: number;
}

const Auction: React.FC<AuctionProps> = ({
                                             name,
                                             image,
                                             description,
                                             startingPrice,
                                             reservePrice,
                                             duration,
                                         }) => {
    const [timeRemaining, setTimeRemaining] = useState<number>(duration);
    const [bidAmount, setBidAmount] = useState<number>(startingPrice);

    useEffect(() => {

        const timer = setTimeout(() => {
            setTimeRemaining(timeRemaining - 1);
        }, 1000);
        return () => clearTimeout(timer);
    }, [timeRemaining]);

    const handleBuy = () => {
        setBidAmount(bidAmount + 1);
    };

    const getAuctionData = async (): Promise<IAuctionData> => {
        const response = await axios.get('https://localhost:7148/Price?auctionId=3').catch((error) => {
            console.log(error);
        });
        console.log(response);
        if (response) {
            return response.data;
        }
        throw new Error("No response");
    }

    const formatPrice = (price: number): string => {
        return price.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    };

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
    };

    const renderItem = (item:ReactImageGalleryItem) => {
        return (
            <div className="image-wrapper">
                <img
                    src={item.original}
                    alt={item.originalAlt}
                    title={item.originalTitle}
                    style={{ width: '50%', height: 'auto' }}
                />
            </div>
        );
    }

    const images = [
        {
            original: 'https://picsum.photos/id/1018/1000/600/',
            thumbnail: 'https://picsum.photos/id/1018/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1015/1000/600/',
            thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
    ];

    const handleShowNotification = () => {
        const options: ToastOptions = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        };
        notify("Price Drop Alert", NotifyEnum.SUCCESS, options);
    }

    const [ auctionData, setAuctionData ] = useState<IAuctionData>();
    const wsAuctionData = WebSocketAuctionData();

    useEffect(() => {
         getAuctionData().then((auctionData) => {
            setAuctionData(auctionData);
        })

    }, []);

    useEffect(() => {
        // check if auction id matches so we update the correct auction data
        if (1 === wsAuctionData?.auctionId) {
            setAuctionData(wsAuctionData);
        }
    }, [wsAuctionData]);


    return (
        <div>
            <br/>
            <img src={image} alt={name} style={{height: 250, width: "auto"}}/>
            <h2>{name}</h2>
            <button onClick={() => handleShowNotification()}>Show notification</button>
             <ImageGallery items={images} renderItem={renderItem} showPlayButton={false} showFullscreenButton={false} showNav={true} showBullets={true} showThumbnails={true}/>
            <p>{description}</p>
            <p>
                Current price: {formatPrice(auctionData?.price || 0)}
                {reservePrice && (
                    <>
                        <br/>
                        Reserve price: {formatPrice(reservePrice)}
                    </>
                )}
            </p>
            <p>Time remaining: {formatTime(timeRemaining)}</p>
            <PriceReminderForm />
            <button onClick={handleBuy}>Buy it</button>
        </div>
    );
};

export default Auction;
