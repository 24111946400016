import React, { useState } from "react";
import { Form, Input, Button } from "antd";

interface PriceReminderFormProps {
}

const PriceReminderForm: React.FC<PriceReminderFormProps> = () => {
    const [price, setPrice] = useState<number>(0);

    const handlePriceRemindFormSubmit = () => {
        console.log("handlePriceRemindFormSubmit for price: ", price);
    };

    return (
        <Form layout="inline" >
            <Form.Item label="Set price reminder">
                <Input
                    type="number"
                    placeholder="Price"
                    onChange={(event) => setPrice(Number(event.target.value))}
                    value={price}
                    required
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" onClick={() => handlePriceRemindFormSubmit()}>
                    Set
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PriceReminderForm;
