import React, { useState, useEffect } from "react";
import {ToastContainer, toast, ToastOptions} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {NotifyEnum} from "./NotifyEnum";

export const NotificationListener = () => {
    const [socket, setSocket] = useState<WebSocket | null>(null);

    useEffect(() => {
        // const newSocket = new WebSocket("ws://example.com");
        //
        // newSocket.addEventListener("message", (event) => {
        //     const message = JSON.parse(event.data);
        //     if (message.price < 155) {
        //         notify("Price Drop Alert", { body: "The price has dropped below $155!" });
        //     }
        // });
        //
        // setSocket(newSocket);
        //
        // return () => {
        //     newSocket.close();
        // };
        console.log("NotificationListener useEffect");
    }, []);


    return null;

}

export const notify = (message: string, type: NotifyEnum, options?: ToastOptions) => {
    switch (type) {
        case NotifyEnum.ERROR:
            toast.error(message, options);
            break;
        case NotifyEnum.INFO:
            toast.info(message, options);
            break;
        case NotifyEnum.SUCCESS:
            toast.success(message, options);
            break;
        case NotifyEnum.WARNING:
            toast.warn(message, options);
            break;
        default: {
            toast(message, options);
        }
    }
}
