import React, {useContext, useEffect, useState} from "react";
import {HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import { Config } from "../config/Config";
import {UpdatePriceMessage} from "./UpdatePriceMessage";

export const WebSocketAuctionData = () => {
    // generate state object auction with parameter auctionId and price
    const [auction, setAuction] = useState<UpdatePriceMessage>({auctionId: 0, price: 0});


    const connectLikeHub = ():HubConnection => {
        //This is the server under /example/server published on azure.
        console.log("start connecting to likeHub");
        const connection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl(Config.priceHubUrl,
                {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets
                })
            .withAutomaticReconnect()
            .build();

        connection.keepAliveIntervalInMilliseconds = 1000;

        connection.start().then(() => {
            console.log("Connection started connectionId=" + connection.connectionId);

            //if connectionID is null then the connection is not established yet and we should wait for it
            if (connection.connectionId === null) {
                    setTimeout(() => {
                        connection.invoke("Alive", "messsage to .NET").catch(err => console.error(err.toString()));
                        console.log("Alive sent");
                    } , 1000);
            }
            connection.onreconnecting((error) => {
                console.log("Reconnecting");
                console.log(error);
            });

            // connection.invoke("Send", "User2", "Hello from the client2!");
        }).catch(err => console.error(err.toString()));


        connection.onclose(() => {
            console.log("Connection closed");
        });

        return connection;
    }

    useEffect(() => {
        const connection = connectLikeHub();
        connection.on("UpdatePrice", (price: UpdatePriceMessage) => {
            console.log("UpdatePrice", price);
            setAuction({auctionId: price.auctionId, price: price.price});
        });

        return () => {
            connection.stop();
        };
    }, []);



    return auction;
};
