import { Form, Input, Button, Modal } from "antd";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

type LoginModalProps = {
    visible: boolean;
    onCancel: () => void;
    onRegister: () => void;
};

const LoginModal = ({ visible, onCancel, onRegister }: LoginModalProps) => {
    const { login } = useContext(AuthContext);

    const onFinish = (values: any) => {
        login(values.email, values.password);
    };

    return (
        <Modal visible={visible} onCancel={onCancel} footer={null}>
            <h2>Login</h2>
            <Form onFinish={onFinish}>
                <Form.Item name="email" rules={[{ required: true, type: "email" }]}>
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true }]}>
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                    <Button type="link" onClick={onRegister}>
                        Register now
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default LoginModal;
