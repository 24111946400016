import React, {useEffect} from 'react';
import './App.css';
import Auction from "./components/Auction";
import {AuthProvider} from "./contexts/AuthContext";
import {SignInSignUp} from "./components/SignInSignUp";
import {NotificationListener} from "./components/Notifications/NotificationListener";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderComponent from "./components/Header/Header";
import {PriceProvider} from "./contexts/PriceContext";

function App() {
    return (
        <AuthProvider>
            <PriceProvider>
            <NotificationListener />
            <ToastContainer />
            <div className="App">
                <HeaderComponent />
                <header className="App-header">

                    {/*<img src={logo} className="App-logo" alt="logo" />*/}
                    {/*<p>*/}
                    {/*  Edit <code>src/App.tsx</code> and save to reload.*/}
                    {/*</p>*/}
                    {/*<a*/}
                    {/*  className="App-link"*/}
                    {/*  href="https://reactjs.org"*/}
                    {/*  target="_blank"*/}
                    {/*  rel="noopener noreferrer"*/}
                    {/*>*/}
                    {/*  Learn React*/}
                    {/*</a>*/}
                    <SignInSignUp />
                    <Auction name={"testAuction"}
                             image={"https://mobiel-product.imgix.net/1sel08esc1l7tkjr8w57ynor04pb"}
                             description={"super popis"} startingPrice={60000} duration={200}/>
                </header>
            </div>
            </PriceProvider>
        </AuthProvider>
    );
}

export default App;
